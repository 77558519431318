<template>
  <div
    :key="uid"
    :class="className"
  >
    <div
      :id="uid"
      :class="useBorder ? 'v-progress-bar-meter v-progress-bar-meter--border' : 'v-progress-bar-meter'"
      :style="`width: ${percent}%`"
    />
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const {
  percent,
  theme = 'primary',
  useBorder = false
} = defineProps<{
  percent: number
  theme?: 'body' | 'popup' | 'primary' | 'secondary'
  useBorder?: boolean
}>()
const uid = useId()

const className = computed<string>(() => {
  let result = `v-progress-bar v-progress-bar--${theme}`

  if (useBorder) {
    result += ` v-progress-bar--border v-progress-bar-border--${theme}`
  }

  return result
})

watch(
  () => percent,
  () => {
    let value = 0
    if (!percent || percent < 0) value = 0
    else if (percent > 100) value = 100
    else value = percent

    gsap.to(`#${uid}`, {
      duration: 0.15,
      ease: 'sine.inOut',
      width: `${value}%`
    })
  },
  { immediate: true }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-progress-bar {
  box-sizing: content-box;
  height: 16px;
  position: relative;
  border-radius: variables.$BorderRadius;
  padding: 0;
  border: 1px solid variables.$BorderColor;
  overflow: hidden;

  &--border {
    padding-right: 2px;
  }

  .v-progress-bar-meter {
    display: block;
    border-radius: variables.$BorderRadius;
    position: relative;
    overflow: hidden;
    height: 100%;

    &--border {
      margin: 1px;
      height: calc(100% - 2px);
    }
  }
}

.v-progress-bar--body {
  background: variables.$BodyElementsBackgroundOpaq50;
  height: 2px;

  .v-progress-bar-meter {
    background-color: variables.$BodyElementsBackground;
  }
}

.v-progress-bar--popup {
  background: variables.$PopupBackgroundOpaque;
  height: 2px;

  .v-progress-bar-meter {
    background-color: variables.$PopupColor;
  }
}

.v-progress-bar--primary {
  background: variables.$PrimaryBackgroundColorOpaq25;

  &.v-progress-bar-border--primary {
    border: 1px solid variables.$PrimaryBackgroundColor;
  }

  .v-progress-bar-meter {
    background-color: variables.$PrimaryBackgroundColor;
  }
}

.v-progress-bar--secondary {
  background: variables.$SecondaryBackgroundColorLightest;

  &.v-progress-bar-border--secondary {
    border: 1px solid variables.$SecondaryBackgroundColor;
  }

  .v-progress-bar-meter {
    background-color: variables.$SecondaryBackgroundColor;
  }
}
</style>
